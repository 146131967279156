<template>
    <div>
        <!-- start page title -->
        <div class="row mt-4 mb-3">
            <div class="col-12">
                <div class="page-title-box" >
                    <h4 class="page-title mb-0 pb-0">Таймеры</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'TimerLayout',
};
</script>

<style>
.description { 
    font-size: 13px;
}
.description p {
    margin-bottom: 0.5rem;
}
.page-title {
    line-height: 1rem !important;
}
</style>